import axios from 'axios';
import {GET_CHASE_CAL, GET_DISCOVER_CAL, CAL_LOADING} from './types';
import {client} from './client';

export const getChaseCal = () => dispatch => {
    dispatch(setCalLoading());
    client.get('/api/chase/cal').then(res =>
        dispatch({
            type: GET_CHASE_CAL,
            payload: res.data,
        }));
};

export const getDiscoverCal = () => dispatch => {
    dispatch(setCalLoading());
    client.get('/api/discover/cal').then(res =>
        dispatch({
            type: GET_DISCOVER_CAL,
            payload: res.data,
        }));
};



export const setCalLoading = () => {
    return {
        type: CAL_LOADING,
    };
};

