import React, {Component} from 'react';
import './styles/App.scss';
import {Provider} from 'react-redux';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import SimpleTabs from './components/SimpleTabs';
import Pay from './views/pay';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Header/>
                    <div className="App">

                        <Switch>
                            <Route path="/pay">
                                <Pay/>
                            </Route>
                            <Route path="/">
                                <SimpleTabs/>
                            </Route>
                        </Switch>
                    </div>
                </Router>

            </Provider>
        );
    };
}

export default App;
