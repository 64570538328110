import React, {Component} from 'react';

class Pay extends Component {

    render() {
        return (
            <div>
                here
            </div>

        );
    }
}

export default Pay;
