import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Calendar from './Calendar';
import TabPanel from './TabPanel';
import PropTypes from 'prop-types';


class SimpleTabs extends Component {

    state = {
        value: 0,
    };

    prevScrollpos = window.pageYOffset;

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        let currentScrollPos = window.pageYOffset;
        if (this.prevScrollpos > currentScrollPos) { //show
            document.getElementById('tabs').style.bottom = '0';
        } else if (currentScrollPos > 13) { // hide
            document.getElementById('tabs').style.bottom = '-55px';
        }
        this.prevScrollpos = currentScrollPos;
    };

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    render() {
        return (

            <div className="simple-tabs">
                <AppBar position="static" className="tabs" id="tabs">
                    <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">
                        <Tab label="🗓" {...this.a11yProps(0)} />
                        <Tab label="💳" {...this.a11yProps(1)} />
                        <Tab label="✔️" {...this.a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0} className="calendar-panel">
                    <Calendar/>
                </TabPanel>
                <TabPanel value={this.state.value} index={1} className="card-panel">
                    Item Two
                </TabPanel>
                <TabPanel value={this.state.value} index={2} className="shopping-panel">
                    Item Three
                </TabPanel>
            </div>
        );
    }
}

export default SimpleTabs;
