import React, {Component} from 'react';
import CategoryModal from './CategoryModal';

const header = "Discover it®";

class Discover extends Component {

    render() {
        const { discoverCal } = this.props;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        let currQuarter = Math.ceil(currentMonth / 3);
        let currYear = d.getFullYear().toString();

        return (

            <section className='discover-table table'>
                <h4 className='cal-header'>
                    {header}
                </h4>
                {
                    discoverCal.map((q, index) => (
                        <div className={`row ${q.quarter.num === currQuarter && q.year === currYear ? "highlight": ""}`} key={index}>
                            <div className='quarter-cell'>
                                <span>{q.quarter.range}</span>
                                <span>{q.year}</span>
                            </div>
                            <div className='category-cell'>
                                <CategoryModal
                                    key={q._id}
                                    header={header}
                                    name={q.category}
                                    terms={q.terms}/>
                            </div>
                        </div>
                    ))
                }
            </section>

        );
    }
}

export default Discover;
