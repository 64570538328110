import {GET_CHASE_CAL, CAL_LOADING, GET_DISCOVER_CAL} from '../actions/types';

const initialState = {
    chaseCal: [],
    discoverCal: [],
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_CHASE_CAL:
            return {
                ...state,
                chaseCal: action.payload,
                loading: false
            };
        case GET_DISCOVER_CAL:
            return {
                ...state,
                discoverCal: action.payload,
                loading: false
            };
        case CAL_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}
