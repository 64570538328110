import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

class Header extends Component {

    prevScrollpos = window.pageYOffset;

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        let currentScrollPos = window.pageYOffset;
        if (this.prevScrollpos > currentScrollPos) { //show
            document.getElementById('name').style.top = '0';
        } else if (currentScrollPos > 13) { // hide
            document.getElementById('name').style.top = '-50px';
        }
        this.prevScrollpos = currentScrollPos;
    };

    render() {
        return (
            <div className="header nav-down" id="name">
                <h3 className="name">thoughtfulpoints</h3>
                {/*<span className="whoami">whoami</span>*/}
                <Link to="/" className="navCategory calNav">calendar</Link>
                <Link to="/pay" className="navCategory payNav">pay</Link>
            </div>

        );
    }
}

export default Header;
