import React, {Component} from 'react';

class Loading extends Component {
    render() {
        return (
            <div className="loading-container">
                <div className="loader">
                    <div className="outer"/>
                    <div className="middle"/>
                    <div className="inner"/>
                </div>
            </div>
        );
    }
}

export default Loading;
