import React, {Component} from 'react';
import CategoryModal from './CategoryModal';

const header = "Chase Freedom®";

class Chase extends Component {

    render() {
        const {chaseCal} = this.props;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        let currQuarter = Math.ceil(currentMonth / 3);

        return (

            <section className='chase-table table'>
                <h4 className='cal-header'>
                    {header}
                </h4>
                {
                    chaseCal.map((q, index) => (
                        <div className={`row ${q.quarter.num === currQuarter ? "highlight": ""}`} key={index}>
                            <div className='quarter-cell'>
                                <span>{q.quarter.range}</span>
                                <span>{q.year}</span>
                            </div>
                            <div className='category-cell'>
                                {q.categories.map(({ name, term, _id }) => (
                                    <CategoryModal
                                        key={_id}
                                        header={header}
                                        name={name}
                                        terms={term}
                                    />
                                ))}
                            </div>
                        </div>
                    ))
                }
            </section>

        );
    }
}

export default Chase;
