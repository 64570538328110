import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getChaseCal, getDiscoverCal} from '../actions/calendarActions';
import PropTypes from 'prop-types';
import Loading from './Loading';
import Chase from './Chase';
import Discover from './Discover';

class Calendar extends Component {

    static propTypes = {
        getChaseCal: PropTypes.func.isRequired,
        calendars: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.props.getChaseCal();
        this.props.getDiscoverCal();
    }

    render() {
        const { chaseCal, discoverCal } = this.props.calendars;

        if (chaseCal.length < 1 || discoverCal.length < 1) {
            return (
                <Loading/>
            );
        } else {
            return (
                <div>
                    <Chase chaseCal={chaseCal} />
                    <Discover discoverCal={discoverCal} />
                </div>


            );
        }

    }
}

const mapStateToProps = (state) => ({
    calendars: state.calendars,
});

export default connect(mapStateToProps, { getChaseCal, getDiscoverCal })(Calendar);
