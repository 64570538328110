import React, {Component} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

class CategoryModal extends Component {
    state = {
        modal: false,
    };

    static propTypes = {
        name: PropTypes.string,
        header: PropTypes.string,
        terms: PropTypes.string,
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    render() {
        const { name, terms, header } = this.props;

        return (
            <div className="category-modal">
                <span onClick={this.toggle} className="category-name">
                    {name}
                </span>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader>
                        {`${header} - ${name}`}
                    </ModalHeader>
                    <ModalBody>
                        {parse(terms)}
                        <Button
                            color='dark' onClick={this.toggle} style={{ marginTop: '2rem' }} block>
                            Close
                        </Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default CategoryModal;
